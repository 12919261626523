import request from '@/utils/request'

export function GetEquipmentBoard(params) {
  return request({
    url: 'api/Equipment/GetEquipmentBoard',
    method: 'get',
    params
  })
}
export function GetEquipmentRealTimeKPIInfo(params) {
  return request({
    url: 'api/Equipment/GetEquipmentRealTimeKPIInfo',
    method: 'get',
    params
  })
}

export function GetEquipmentBrokenDetail(params) {
  return request({
    url: 'api/Equipment/GetEquipmentBrokenDetail',
    method: 'get',
    params
  })
}

export function GetEquipmentAlarmResultInfo(params) {
  return request({
    url: 'api/Equipment/GetEquipmentAlarmResultInfo',
    method: 'get',
    params
  })
}

