import request from '@/utils/request'

export function GetLocationsList(params) {
  return request({
    url: 'api/Equipment/GetLocationsList',
    method: 'get',
    params
  })
}
export function GetEquipmentElectritityDetail(params) {
  return request({
    url: 'api/Equipment/GetEquipmentElectritityDetail',
    method: 'get',
    params
  })
}
//业务单元
export function GetBusinessUnitList(params) {
  return request({
    url: 'api/Equipment/GetBusinessUnitList',
    method: 'get',
    params
  })
}
//事业部-车间
export function GetBUAgencyListWithAgencyType(params) {
  return request({
    url: 'api/Equipment/GetBUAgencyListWithAgencyType',
    method: 'get',
    params
  })
}
//班组-工段-作业单元
export function GetWorkTeamListWithWorkGroupType(params) {
  return request({
    url: 'api/Equipment/GetWorkTeamListWithWorkGroupType',
    method: 'get',
    params
  })
}
//设备类型
export function GetEquipmentTypeList(params) {
  return request({
    url: 'api/Equipment/GetEquipmentTypeList',
    method: 'get',
    params
  })
}
//设备型号
export function GetModelList(params) {
  return request({
    url: 'api/Equipment/GetModelList',
    method: 'get',
    params
  })
}
//确定 
export function GetEquipmentMonitorList(params) {
  return request({
    url: 'api/Equipment/GetEquipmentMonitorList',
    method: 'get',
    params
  })
}
//获取设备图片
export function GetEquipmentImage(params) {
  return request({
    url: 'api/Equipment/GetEquipmentImage',
    method: 'get',
    params
  })
}
//获取设备工作状态
export function GetEquipmentCollectDataList(params) {
  return request({
    url: 'api/Equipment/GetEquipmentCollectDataList',
    method: 'get',
    params
  })
}
//获取设备实时绩效
export function GetEquipmentPerformanceInfo(params) {
  return request({
    url: 'api/Equipment/GetEquipmentPerformanceInfo',
    method: 'get',
    params
  })
}
//获取设备用电状态监控
export function GetEquipmentElectritityInfo(params) {
  return request({
    url: 'api/Equipment/GetEquipmentElectritityInfo',
    method: 'get',
    params
  })
}
//点击设备展示
export function GetSelectedEquipmentMonitor(params) {
  return request({
    url: 'api/Equipment/GetSelectedEquipmentMonitor',
    method: 'get',
    params
  })
}

//设备运维管理 基本信息
export function GetEquipmentBasicInfos(params) {
  return request({
    url: 'api/Equipment/GetEquipmentBasicInfos',
    method: 'get',
    params
  })
}
//设备故障管理 基本信息
export function GetEquipmentWorkOrders(params) {
  return request({
    url: 'api/Equipment/GetEquipmentWorkOrders',
    method: 'get',
    params
  })
}
//添加设备故障信息
export function AddEquipmentWorkOrder(params,headers) {
  return request({
    url: 'api/Equipment/AddEquipmentWorkOrder',
    method: 'post',
    data:params,
    headers
  })
}
//更新设备故障信息
export function UpdateEquipmentWorkOrder(params,headers) {
  return request({
    url: 'api/Equipment/UpdateEquipmentWorkOrder',
    method: 'put',
    data:params,
    headers
  })
}
//获取设备故障信息 及文件列表
export function GetEquipmentOrder(params) {
  return request({
    url: 'api/Equipment/GetEquipmentWorkOrder',
    method: 'get',
    params
  })
}
//获取工单类型
export function GetWorkOrderContentType(params) {
  return request({
    url: 'api/Equipment/GetWorkOrderContentType',
    method: 'get',
    params
  })
}
//设备故障工单下载文件
export function DownloadWorkOrderFile(params) {
  return request({
    url: 'api/Equipment/DownloadWorkOrderFile',
    method: 'get',
    params
  })
}
//设备故障 设备预警列表
export function GetEqWorkOrders(params) {
  return request({
    url: 'api/Equipment/GetEqWorkOrders',
    method: 'get',
    params
  })
}

//计划名称
export function GetDayWorkNames(params) {
  return request({
    url: 'api/Equipment/GetDayWorkNames',
    method: 'get',
    params
  })
}
//获取开关计划
export function GetDayWorkPlanById(params) {
  return request({
    url: 'api/Equipment/GetDayWorkPlanById',
    method: 'get',
    params
  })
}
//添加开机计划
export function AddEquipmentWorkPlan(data) {
  return request({
    url: 'api/Equipment/AddEquipmentWorkPlan',
    method: 'post',
    data
  })
}
//更新开机计划
export function UpdateEquipmentWorkPlan(data) {
  return request({
    url: 'api/Equipment/UpdateEquipmentWorkPlan',
    method: 'post',
    data
  })
}

//根据厂房位置获取设备信息
export function GetEquipmentByLoc(params) {
  return request({
    url: '/api/Equipment/GetEquipmentByLoc',
    method: 'get',
    params
  })
}
//根据设备状态展示
export function GetEquipmentByStates(params) {
  return request({
    url: '/api/Equipment/GetEquipmentByStates',
    method: 'get',
    params
  })
}

//获取设备预警
export function GetAlarmInfos(params) {
  return request({
    url: '/api/Equipment/GetAlarmInfos',
    method: 'get',
    params
  })
}
//报警设备名称
export function GetEuipments(params) {
  return request({
    url: '/api/Equipment/GetEuipments',
    method: 'get',
    params
  })
}
//报警表格列表
export function GetEuipmentAlarmInfos(params) {
  return request({
    url: '/api/Equipment/GetEuipmentAlarmInfos',
    method: 'get',
    params
  })
}
//详情tab信息展示
export function GetEuipmentPars(params) {
  return request({
    url: '/api/Equipment/GetEuipmentPars',
    method: 'get',
    params
  })
}



