<template>
	<div class="ProductionManagementKanban">
		<headers :title="Header_title"></headers>
		<div class="container_top">
			<el-row :gutter="10">
			  <el-col :span="17"
					v-loading="loading"
					element-loading-text="加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(1,34,48, 0)">
					<div class="Cont_DIv_left">
						<div class="Cont_content">
							<div class="Cont_cont_text ">
								<div>生产设备总数</div>
								<div class="Cont_cont_num">{{EquipmentAmount.AllAmount}}</div>
							</div>
							<div class="Cont_cont_box">
								<div class="Cont_infoBox BGimg1 mb15" @click="handleGoRouter(3)">
									<div class="Cont_infoMsg">
										<span class="Cont_circle Cont_cir1"></span>
										<span class="Cont_title">运行设备数</span>
										<span class="Cont_num Cont_num1">{{EquipmentAmount.RunningAmount}}</span>
									</div>
									<div class="Cont_rit_icon"></div>
								</div>
								<div class="Cont_infoBox BGimg2" @click="handleGoRouter(-1)">
									<div class="Cont_infoMsg">
										<span class="Cont_circle Cont_cir2"></span>
										<span class="Cont_title">停机设备数</span>
										<span class="Cont_num Cont_num2">{{EquipmentAmount.ShutDownAmount}}</span>
									</div>
									<div class="Cont_rit_icon"></div>
								</div>
							</div>

							<div class="Cont_cont_box">
								<div class="Cont_infoBox BGimg3 mb15" @click="handleGoRouter(0)">
									<div class="Cont_infoMsg">
										<span class="Cont_circle Cont_cir3"></span>
										<span class="Cont_title">待机设备数</span>
										<span class="Cont_num Cont_num3">{{EquipmentAmount.StandByAmount}}</span>
									</div>
									<div class="Cont_rit_icon"></div>
								</div>
								<div class="Cont_infonoBox BGimg4">
									<div class="Cont_infoMsg">
										<span class="Cont_circle Cont_cir4"></span>
										<span class="Cont_title">故障设备数</span>
										<span class="Cont_num Cont_num4">{{EquipmentAmount.BadAmount}}</span>
									</div>
									<div class="Cont_rit_icon"></div>
								</div>
							</div>
						</div>
					</div>
			  </el-col>
				<el-col :span="7" 
					v-loading="alarmEquloading"
					element-loading-text="加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(1,34,48, 0)">
			    <div class="Cont_DIv_right" @click="handleGo2Router">
						<div class="Cont_onceInfo">
							<div class="Cont_onceBox">
								<span class="Cont_title">报警设备数</span>
								<span class="Cont_rit_icon"></span>
							</div>
			      	<!-- <div class="Cont_cont_num">{{EquipmentAmount.AlarmAmount}}</div> -->
			      	<div class="Cont_cont_num">{{AlarmEquipAmount}}</div>
						</div>
			    </div>
			  </el-col>
			</el-row>	
		</div>
		<!---->
		<div class="container_centre">
			<el-row :gutter="10">
			  <el-col :span="10">
				<div class="BodyMain BodyMainBg1" v-loading="loading"
				  element-loading-text="加载中"
				  element-loading-spinner="el-icon-loading"
				  element-loading-background="rgba(1,34,48, 0)">
					<div class="gaugeoutfit">
					   <span class="xian"></span>
					   <span class="title">生产执行概览</span>
					</div>
					<div class="detailed">
						<el-row :gutter="10">
						  <el-col :span="8">
						    <div class="production">
							  <span>
								<img src="../../../static/icon/Plannedproduction.png" />
							  </span>
						      <span>今日计划产量(只)</span>
						      <span class="span1">{{WordResult.PlanAmount}}</span>
						    </div>
						  </el-col>
						  <el-col :span="8">
						    <div class="production">
							  <span>
							  	<img src="../../../static/icon/Output.png" />
							  </span>
						      <span>当前实际产量(只)</span>
						      <span class="span1">{{WordResult.ActualAmount}}</span>
						    </div>
						  </el-col>
						  <el-col :span="8">
						    <div class="production">
							  <span>
							  	<img src="../../../static/icon/attendance.png" />
							  </span>
						      <span>今日出勤率(%)</span>
						      <span class="span1">{{WordResult.DutyPercent}}</span>
						    </div>
						  </el-col>
						</el-row>
						<el-row :gutter="10">
						  <el-col :span="8">
						    <div class="production">
							  <span>
							  	<img src="../../../static/icon/Productioncompleted.png" />
							  </span>
						      <span>预期完成产量(只)</span>
						      <span class="span2">{{WordResult.ExpectAmount}}</span>
						    </div>
						  </el-col>
						  <el-col :span="8">
						    <div class="production">
							  <span>
							  	<img src="../../../static/icon/Plancompletionrate.png" />
							  </span>
						      <span>预期计划完成率(%)</span>
						      <span class="span2">{{WordResult.ExpectPercent}}</span>
						    </div>
						  </el-col>
						  <el-col :span="8">
						    <div class="production">
							  <span>
							  	<img src="../../../static/icon/Totalworkinghours.png" />
							  </span>
						      <span>今日总工时(分钟)</span>
						      <span class="span2">{{WordResult.TotalWorkTime}}</span>
						    </div>
						  </el-col>
						</el-row>
						<el-row :gutter="10">
						  <el-col :span="8">
						    <div class="production">
							  <span>
							  	<img src="../../../static/icon/Goodproductyield.png" />
							  </span>
						      <span>实际良品产量(只)</span>
						      <span class="span3">{{WordResult.GoodAmount}}</span>
						    </div>
						  </el-col>
						  <el-col :span="8">
						    <div class="production">
							  <span>
							  	<img src="../../../static/icon/YIELD.png" />
							  </span>
						      <span>良品率(%)</span>
						      <span class="span3">{{WordResult.GoodPercent}}</span>
						    </div>
						  </el-col>
						  <el-col :span="8">
						    <div class="production">
							  <span>
							  	<img src="../../../static/icon/Materialloss.png" />
							  </span>
						      <span>物料损耗(%)</span>
						      <span class="span3">{{WordResult.MaterialLossPercent}}</span>
						    </div>
						  </el-col>
						</el-row>
					</div>
				</div>
			  </el-col>
			  <el-col :span="7">
			  	<div class="BodyMain BodyMainBg2">
					<div class="gaugeoutfit">
					   <span class="xian"></span>
					   <span class="title">设备综合效率</span>
					</div>
					<div class="efficiency">
						<div class="efficiency_l">
							<div id="score" v-loading="loading"
							  element-loading-text="加载中"
							  element-loading-spinner="el-icon-loading"
							  element-loading-background="rgba(1,34,48, 0)"></div>
							<span class="span1">设备综合稼动率</span>
							<span class="span2">目标：{{EffectResult.TargetActiveRate}}%</span>
						</div>
						<div class="efficiency_r">
							<div class="efficiencyList">
								<span>运行时长：</span>
								<span>{{EffectResult.TotalWorkTime}} <div class="unit">小时</div></span>
								<span>{{EffectResult.TotalWorkPercent}}%</span>
							</div>
							<div class="efficiencyList2">
								<span>待机时长：</span>
								<span>{{EffectResult.TotalStandByTime}} <div class="unit">小时</div></span>
								<span>{{EffectResult.TotalStandByPercent}}%</span>
							</div>
							<div class="efficiencyList3">
								<span>故障时长：</span>
								<span>{{EffectResult.TotalBadTime}} <div class="unit">小时</div></span>
								<span>{{EffectResult.TotalBadPercent}}%</span>
							</div>
						</div>
					</div>
				</div>			  
			  </el-col>
			  <el-col :span="7">
			  	<div class="BodyMain BodyMainBg3" v-loading="loading"
				  element-loading-text="加载中"
				  element-loading-spinner="el-icon-loading"
				  element-loading-background="rgba(1,34,48, 0)">
					<div class="gaugeoutfit">
					   <span class="xian"></span>
					   <span class="title">设备用能概览</span>
					</div>
					<div class="Energy">
						<el-row :gutter="10">
						  <el-col :span="12">
						    <div class="EnergyCont">
							  <span>
							  	<img src="../../../static/icon/Todayconsumption.png" />
							  </span>
						      <span>{{ElectricityResult.ElectricityAmount}} <div class="unit">kWh</div></span>
						      <span>今日用电量</span>
						    </div>
						  </el-col>
						  <el-col :span="12">
						    <div class="EnergyCont">
							  <span>
							  	<img src="../../../static/icon/Plannedconsumption.png" />
							  </span>
						      <span>{{ElectricityResult.PlanElectricityAmount}} <div class="unit">kWh</div></span>
						      <span>计划用电量</span>
						    </div>
						  </el-col>
						</el-row>
						<el-row :gutter="10">
						  <el-col :span="12">
						    <div class="EnergyCont">
							  <span>
							  	<img src="../../../static/icon/Productconsumption.png" />
							  </span>
						      <span>{{ElectricityResult.AvgAmountPerProduct}} <div class="unit">kWh</div></span>
						      <span>单位产品用电量</span>
						    </div>
						  </el-col>
						  <el-col :span="12">
						    <div class="EnergyCont">
							  <span>
							  	<img src="../../../static/icon/Ratedconsumption.png" />
							  </span>
						      <span>{{ElectricityResult.PlanAvgAmountPerProduct}} <div class="unit">kWh</div></span>
						      <span>单位产品用电定额</span>
						    </div>
						  </el-col>
						</el-row>
						<el-row :gutter="10">
						  <el-col :span="12">
						    <div class="EnergyCont">
							  <span>
							  	<img src="../../../static/icon/Averageconsumption.png" />
							  </span>
						      <span>{{ElectricityResult.AvgAmountPerEquipment}} <div class="unit">kWh</div></span>
						      <span>设备平均用电量</span>
						    </div>
						  </el-col>
						  <el-col :span="12"></el-col>
						</el-row>
					</div>
				</div>			  
			  </el-col>
			</el-row>
		</div>
		<!---->
		<div class="container_bottom">
			<el-row :gutter="10">
			  <el-col :span="7">
				<div class="BodyMain2 BodyMainBg4" v-loading="loading2"
					  element-loading-text="加载中"
					  element-loading-spinner="el-icon-loading"
					  element-loading-background="rgba(1,34,48, 0)">
					<div class="gaugeoutfit">
					   <span class="xian"></span>
					   <span class="title">设备实时绩效</span>
					</div>
					<!--进度条-->
					<div class="progress" v-if="tableData.length>0">
						<div class="progress_l">设备利用率 (%)</div>
						<div class="progress_r">{{UtilRate}}</div>
						<el-progress :percentage="UtilRate>100?100:UtilRate" status="success" :text-inside="false" :stroke-width="8" ></el-progress>
						<el-table
						  :data="tableData"
						  :header-cell-style="{ 'background':BackgroundColors}"
						  style="width: 100%;margin-top: 2vh;">
						  <el-table-column
							prop="ProductName"
							min-width="100"
							label="产品名称">
						  </el-table-column>
						  <el-table-column
							prop="CycleTime"
							label="理论节拍">
						  </el-table-column>
						  <el-table-column
							prop="ActualCycleTime"
							label="实际节拍">
						  </el-table-column>
						  <el-table-column
							prop="PerHourAmount"
							label="小时产出">
						  </el-table-column>
						  <el-table-column
							prop="Amount"
							label="加工数">
						  </el-table-column>
						</el-table>
					</div>
					<span v-show="emptyMsg2" class="icon">
					  <img :src="empty" class="icon-img">
					  <div class="icon-text">暂无数据</div>
					</span>
				</div>
			  </el-col>
			  <el-col :span="10">
			  	<div class="BodyMain2 BodyMainBg5" v-loading="loading3"
					  element-loading-text="加载中"
					  element-loading-spinner="el-icon-loading"
					  element-loading-background="rgba(1,34,48, 0)">
					<div class="gaugeoutfit">
					   <span class="xian"></span>
					   <span class="title">设备运维统计</span>
					</div>
					<div class="maintenance" v-if="statisticsList.length>0">
						<el-row :gutter="10">
						  <el-col :span="12">
						    <div class="maintenanceCont">
							  <span style="color: #d00032;">0</span>
							  <span>今日故障设备</span>
						    </div>
						  </el-col>
						  <el-col :span="12">
							<div class="maintenanceCont">
							  <span style="color: #ff9600;">0</span>
							  <span>今日计划维修</span>
							</div>
						  </el-col>
						</el-row>
						<el-table
						  :data="statisticsList"
						  :header-cell-style="{ 'background':BackgroundColors}"
						  style="width: 100%;margin-top:0.6vh;">
						  <el-table-column
							prop="ModelName"
							label="设备品牌">
						  </el-table-column>
						  <el-table-column
							prop="EquipmentAmount"
							label="设备总数">
						  </el-table-column>
						  <el-table-column
							prop="BrokenEquipmentAmount"
							label="今日故障设备">
						  </el-table-column>
						  <el-table-column
							prop="FixEquipmentAmount"
							label="今日计划维修">
						  </el-table-column>
						</el-table>
					</div>
					<span v-show="emptyMsg3" class="icon">
					  <img :src="empty" class="icon-img">
					  <div class="icon-text">暂无数据</div>
					</span>
				</div>			  
			  </el-col>
			  <el-col :span="7">
			  	<div class="BodyMain2 BodyMainBg6" v-loading="loading4"
					  element-loading-text="加载中"
					  element-loading-spinner="el-icon-loading"
					  element-loading-background="rgba(1,34,48, 0)">
					<div class="gaugeoutfit">
					   <span class="xian"></span>
					   <span class="title">设备报警统计</span>
					</div>
					<div class="callPolice">
						<el-row :gutter="10">
						  <el-col :span="8">
						    <div class="maintenanceCont">
							  <span style="color: #d00032;">{{TotalAlarmAmount}}</span>
							  <span>今日报警</span>
						    </div>
						  </el-col>
						  <el-col :span="8">
							<div class="maintenanceCont">
							  <span style="color: #38cb89;">0</span>
							  <span>已处理报警</span>
							</div>
						  </el-col>
						  <el-col :span="8">
							<div class="maintenanceCont">
							  <span style="color: #ff9600;">0</span>
							  <span>待处理报警</span>
							</div>
						  </el-col>
						</el-row>
						<div  v-if="AlarmDetailList.length>0" class="progress2" v-for="(item,index) in AlarmDetailList" :key="index" style="margin-bottom: 0.5vh;">
							<div class="progress2_l">{{item.ModelName}}</div>
							<div class="progress2_r">{{item.AlarmAmount}}</div>
							<el-progress :percentage="TotalAlarmAmount>0?((item.AlarmAmount/TotalAlarmAmount)*100>100?100:(item.AlarmAmount/TotalAlarmAmount)*100):0" 
							status="success" :text-inside="false" :stroke-width="8" ></el-progress>
						</div>
						<span v-show="emptyMsg4" class="icon">
						  <img :src="empty" class="icon-img">
						  <div class="icon-text">暂无数据</div>
						</span>
					</div>
				</div>			  
			  </el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import { formatDate,getNowFormatDate } from '@/common/js/date.js'
import {isEmpty,convertImg,convert} from '@/common/js/base.js'
import headers from '@/components/header/headermain.vue'
import { GetEquipmentBoard,GetEquipmentRealTimeKPIInfo,GetEquipmentBrokenDetail,GetEquipmentAlarmResultInfo } from "@/api/ProductionManagementKanban"; //页面接口
import { GetAlarmInfos } from "@/api/EquipmentOperationMonitoring"; //页面接口
import empty from '@/assets/icons/empty02.png'

export default{
	components: {
	  headers
    },
	name:"ProductionManagementKanban",
	data(){
		return{
		  Header_title:'生产管理看板',
		  empty:empty,
		  tableData: [],
		  statisticsList:[],
		  loading:false,
		  EquipmentAmount:{},
		  WordResult:{},
		  EffectResult:{},
		  ElectricityResult:{},
		  Comprehensive:'',
		  TimeState:null,
		  
		  loading2:false,
		  emptyMsg2:false,
		  UtilRate:0,
		  
		  loading3:false,
		  emptyMsg3:false,
		  
		  loading4:false,
		  emptyMsg4:false,
		  TotalAlarmAmount:'',
		  AlarmDetailList:[],
		  BgColor: '',
		  BackgroundColors:'',
		  BackgroundImg:sessionStorage.getItem('BgColorType'),
			alarmEquloading:false,
			AlarmEquipAmount:0,
		}
	},
	watch: {
	  '$store.state.testTxt.tips'(newVal,oldVal){
			if(newVal=='white'){
				this.BackgroundColors = '#f9f9f9';
				this.BgColor = '#333333';
			}else{
				this.BackgroundColors = '#2a343b';
				this.BgColor = '#ffffff';
			}
			this.drawScore('score')
		}
	},
	mounted() {
		if(this.BackgroundImg=='white'){
			this.BackgroundColors = '#f9f9f9';
			this.BgColor = '#333333';
	  }else{
			this.BackgroundColors = '#2a343b';
			this.BgColor = '#ffffff';
	  }
		this.handleGetAlarmInfo()//报警设备数  一天的数据
	  this.$nextTick(function() {
			this.inits();
			this.RealTimePerformance();
			this.EquipmentOperationMaintenance();
			this.EquipmentAlarmStatistics();
			this.TimeState= setInterval(() => {//实时数据
				this.inits2();
				this.RealTimePerformance2();
				this.EquipmentOperationMaintenance2();
				this.EquipmentAlarmStatistics2();
			}, 1000*60);
	  })
	  window.addEventListener('resize', ()=>{
	    if(this.$route.path == '/ProductionManagementKanban'){
			this.charts.resize();
			this.drawScore('score')
	    }
	  });
	},
	methods:{
		handleGetAlarmInfo(){
      this.alarmEquloading = true;
      this.AlarmEquipAmount = 0;
      GetAlarmInfos({
        AgencyId:sessionStorage.getItem('agencyId'),//机构id
        EquipmentId:0,
      }).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.alarmEquloading = false;
          this.AlarmEquipAmount = 0;
          this.$notify.info({
            title:'消息',
            message: '报警设备查询失败'
          });
          return;
        }
        this.alarmEquloading = false;
        this.AlarmEquipAmount = obj.alarmEuipmentCount;
      }).catch(err => {
        this.alarmEquloading = false;
        this.AlarmEquipAmount = 0;
      })
    },
		handleGoRouter(LocId){//-1 停机 0 待机 3 运行 5 报警
			this.$router.push({path:'/EquipmentOperaStatusView', query:{
				stateView:LocId,
				title:'设备运行状态监测'
			}})
		},
		handleGo2Router(){
			this.$router.push({path:'/EquipmentAlarmManagement', query:{
				title:'设备报警管理'
			}})
		},
		inits(){
			this.EquipmentAmount = {};
			this.WordResult = {};
			this.EffectResult = {};
			this.ElectricityResult = {};
			this.Comprehensive = '';
			this.loading = true;
			GetEquipmentBoard({
				AgencyId:sessionStorage.getItem('agencyId')
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.EquipmentAmount = {};
				 this.WordResult = {};
				 this.EffectResult = {};
				 this.ElectricityResult = {};
			     this.$notify.info({
			       title: '消息',
			       message: '获取设备信息失败'
			     });
				 this.loading = false;
			     return;
			   }else if(isEmpty(obj)){
			     this.EquipmentAmount = {};
				 this.WordResult = {};
				 this.EffectResult = {};
				 this.ElectricityResult = {};
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
				 this.loading = false;
			     return;
			   }
			   this.loading = false;
			   this.EquipmentAmount = obj.EquipmentAmount
			   this.WordResult = obj.WordResult;
			   this.EffectResult = obj.EffectResult;
			   this.ElectricityResult = obj.ElectricityResult;
			   //设备综合效率百分比
			   this.Comprehensive  = this.EffectResult.ActualActiveRate;
			   this.drawScore('score')
			}).catch(err => {
				
			})
		},
		inits2(){
			GetEquipmentBoard({
				AgencyId:sessionStorage.getItem('agencyId')
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.$notify.info({
			       title: '消息',
			       message: '获取看板内容失败'
			     });
			     return;
			   }else if(isEmpty(obj)){
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
			     return;
			   }
			   this.loading = false;
			   this.EquipmentAmount = obj.EquipmentAmount
			   this.WordResult = obj.WordResult;
			   this.EffectResult = obj.EffectResult;
			   this.ElectricityResult = obj.ElectricityResult;
			   //设备综合效率百分比
			   this.Comprehensive  = this.EffectResult.ActualActiveRate;
			   this.drawScore('score')
			}).catch(err => {
				
			})
		},
		//设备实时绩效
		RealTimePerformance(){
			this.loading2 = true;
			this.emptyMsg2 = false;
			this.tableData = [];
			this.UtilRate = 0;
			GetEquipmentRealTimeKPIInfo({
				AgencyId:sessionStorage.getItem('agencyId')
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.$notify.info({
			       title: '消息',
			       message: '获取设备实时数据失败'
			     });
				 this.loading2 = false;
				 this.emptyMsg2 = true;
			     return;
			   }else if(isEmpty(obj)){
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
				 this.loading2 = false;
				 this.emptyMsg2 = true;
			     return;
			   }
			   this.loading2 = false;
			   this.UtilRate = obj.UtilizationRate;
			   this.tableData = obj.ProductResult;
			}).catch(err => {
				
			})
		},
		RealTimePerformance2(){
			GetEquipmentRealTimeKPIInfo({
				AgencyId:sessionStorage.getItem('agencyId')
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.$notify.info({
			       title: '消息',
			       message: '获取设备实时数据失败'
			     });
				 this.emptyMsg2 = true;
				 this.UtilRate = 0;
				 this.tableData = [];
			     return;
			   }else if(isEmpty(obj)){
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
				 this.emptyMsg2 = true;
				 this.UtilRate = 0;
				 this.tableData = [];
			     return;
			   }
			   this.loading2 = false;
			   this.UtilRate = obj.UtilizationRate;
			   this.tableData = obj.ProductResult;
			}).catch(err => {
				
			})
		},
		//设备运维统计
		EquipmentOperationMaintenance(){
			this.loading3 = true;
			this.emptyMsg3 = false;
			this.statisticsList = [];
			GetEquipmentBrokenDetail({
				AgencyId:sessionStorage.getItem('agencyId')
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.$notify.info({
			       title: '消息',
			       message: '获取设备运维统计失败'
			     });
				 this.loading3 = false;
				 this.emptyMsg3 = true;
			     return;
			   }else if(isEmpty(obj)){
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
				 this.loading3 = false;
				 this.emptyMsg3 = true;
			     return;
			   }
			   this.loading3 = false;
			   this.statisticsList = obj;
			}).catch(err => {
				
			})
		},
		EquipmentOperationMaintenance2(){
			GetEquipmentBrokenDetail({
				AgencyId:sessionStorage.getItem('agencyId')
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.$notify.info({
			       title: '消息',
			       message: '获取设备运维统计失败'
			     });
				 this.emptyMsg3 = true;
				 this.statisticsList = [];
			     return;
			   }else if(isEmpty(obj)){
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
				 this.emptyMsg3 = true;
				 this.statisticsList = [];
			     return;
			   }
			   this.loading3 = false;
			   this.statisticsList = obj;
			}).catch(err => {
				
			})
		},
		EquipmentAlarmStatistics(){
			this.loading4 = true;
			this.emptyMsg4 = false;
			this.TotalAlarmAmount = '';
			this.AlarmDetailList = [];
			GetEquipmentAlarmResultInfo({
				AgencyId:sessionStorage.getItem('agencyId')
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.$notify.info({
			       title: '消息',
			       message: '获取设备运维统计失败'
			     });
				 this.loading4 = false;
				 this.emptyMsg4 = true;
			     return;
			   }else if(isEmpty(obj)){
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
				 this.loading4 = false;
				 this.emptyMsg4 = true;
			     return;
			   }
			   this.loading4 = false;
			   this.TotalAlarmAmount = obj.TotalAlarmAmount;
			   this.AlarmDetailList = obj.AlarmDetail;
			}).catch(err => {
				
			})
		},
		EquipmentAlarmStatistics2(){
			GetEquipmentAlarmResultInfo({
				AgencyId:sessionStorage.getItem('agencyId')
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.$notify.info({
			       title: '消息',
			       message: '获取设备运维统计失败'
			     });
				 this.emptyMsg4 = true;
			     return;
			   }else if(isEmpty(obj)){
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
				 this.emptyMsg4 = true;
			     return;
			   }
			   this.loading4 = false;
			   this.TotalAlarmAmount = obj.TotalAlarmAmount;
			   this.AlarmDetailList = obj.AlarmDetail;
			}).catch(err => {
				
			})
		},
		// 传入div的ID
		    drawScore(id) {
		      // 数据
		      const score = this.Comprehensive;
		      this.charts = this.$echarts.init(document.getElementById(id))
		      this.charts.setOption({
		        title: {
		          text: '今日(%)',
		          textStyle: {
		            color: this.BgColor,
		            fontSize: convert(10),
								fontFamily: "SourceHanSansCN-Regular",
		          },
		          subtext: score,
		          subtextStyle: {
		            color: '#38cb89',
		            fontSize: convert(20),
								fontFamily: 'GemunuLibre-Regular',
		          },
		          itemGap: convert(5), // 主副标题距离
		          left: 'center',
		          top: 'top',
		          padding: convert(65)
		        },
		        angleAxis: {
		          max: 100, // 满分
		          clockwise: true, // 逆时针
		          // 隐藏刻度线
		          axisLine: {
		            show: false
		          },
		          axisTick: {
		            show: false
		          },
		          axisLabel: {
		            show: false
		          },
		          splitLine: {
		            show: false
		          }
		        },
		        radiusAxis: {
		          type: 'category',
		          // 隐藏刻度线
		          axisLine: {
		            show: false
		          },
		          axisTick: {
		            show: false
		          },
		          axisLabel: {
		            show: false
		          },
		          splitLine: {
		            show: false
		          }
		        },
		        polar: {
		          center: ['50%', '50%'],
		          radius: '140%' // 图形大小
		        },
		        series: [{
		          // 圆角环形图
		          type: 'bar',
		          data: [{
		            value: score,
		            itemStyle: {
		              normal: {
		                color: '#38cb89'
		              }
		            }
		          }],
		          coordinateSystem: 'polar',
		          roundCap: true,
		          barWidth: convert(3),
		          barGap: '-100%', // 两环重叠
		          z: 2
		        }, {
		          // 起始点的圆点
		          name: 'Line 1',
		          type: 'pie',
		          startAngle: 270,
		          clockWise: true,
		          radius: ['66%', '66%'], // 控制圆环大小的
		          itemStyle: {
		            normal: {
		              label: {
		                show: true
		              },
		              labelLine: {
		                show: false
		              }
		            }
		          },
		          hoverAnimation: false,
		          data: [{
		            name: '',
		            value: 0,
		            // 起始点的大小
		            label: {
		              position: 'inside',
		              backgroundColor: '#38cb89',
		              width: convert(1),
		              height: convert(1),
		              borderRadius: convert(50),
		              padding: convert(4)
		            }
		          }]
		        },
		        // 虚线那一圈
		        {
		          name: 'linear',
		          type: 'pie',
		          color: ['#38cb89'],
		          center: ['50%', '50%'],
		          radius: ['60%', '57%'],
		          hoverAnimation: false,
		          lable: {
		            normal: {
		              show: false
		            },
		            emphasis: {
		              show: false
		            }
		          },
		          labelLine: {
		            normal: {
		              show: false
		            }
		          },
		          data: new Array(200).fill(1).map(() => {
		            return {
		              name: '',
		              value: 100
		            }
		          })
		        },
		        // 水波求
		        {
		          type: 'liquidFill',
		          data: [(score / 100)],
		          backgroundStyle: { // 背景样式
		            borderWidth: convert(4),
		            color: 'rgba(0,0,0,0)', // 水球未到的背景颜色
		            opacity: 0 // 水球未到的背景颜色的透明度
		          },
		          outline: { // 外边线样式
		            show: false // 是否显示外边线
		          },
		          // color: ['rgba(0,0,0,0)', '#A0C1FF', 'rgba(0,0,0,0)'],
		          itemStyle: {
		            color: '#38cb89',
		            shadowBlur: 0, // 波浪的阴影范围
		            opacity: 0.30	// 透明度
		          },
		          label: {
		            show: false
		          }
		        }
		        ]
		      })
		    }
	},
	beforeDestroy() {
	 clearInterval(this.TimeState);
	 this.TimeState = null;
	},
	
}
</script>

<style scoped="scoped" lang="scss">
@import '@/assets/mixin.scss';
.Cont_onceBox{
	display: flex;
	align-items: center;
}
.Cont_rit_icon{
	display: inline-block;
	width: 20px;
	height: 20px;
	background-image: url('../../../static/images/infoicon.png');
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position:center;
}
.Cont_content{
	display: flex;
	align-items: center;
	position: absolute;
  left: 110px;
	height: 100%;
}
.Cont_cont_text{
	width: 170px;
	margin-right: 20px;
}
.Cont_cont_box{
	margin-right: 20px;
}
.Cont_infoBox{
	width: 360px;
	height: 38px;
	border-radius: 5px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}
.Cont_infonoBox{
	width: 360px;
	height: 38px;
	border-radius: 5px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.mb15{
	margin-bottom: 10px;
}
.Cont_circle{
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
}
.Cont_title{
	margin-right: 18px;
}
.Cont_num{
	font-size: 24px;
	font-family: 'GemunuLibre-Regular';
}
.Cont_cont_num{
	font-family: 'GemunuLibre-Regular';
	font-size: 26px;
}
.BGimg1{
	background-color: #0c292b;
}
.Cont_cir1{
	background-color: #00d392;
}
.Cont_num1{
	color: #00d392;
}
.BGimg2{
	background-color: #253d3c;
}
.Cont_cir2{
	background-color: #cccccc;
}
.Cont_num2{
	color: #cccccc;
}
.BGimg3{
	background-color: #26261e;
}
.Cont_cir3{
	background-color: #ff9600;
}
.Cont_num3{
	color: #ff9600;
}
.BGimg4{
	background-color: #283d2c;
}
.Cont_cir4{
	background-color: #ecc928;
}
.Cont_num4{
	color: #ecc928;
}


@media only screen and (max-aspect-ratio: 1920/920 ) {
.container_top{
	width:100%;
	height:120px;
	border-radius:4px;
}
.Cont_DIv_left{
	height: 120px;
	background-image: url('../../../static/images/Totalnumber.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
	position: relative;
}
.Cont_DIv_right{
	height: 120px;
	background-image: url('../../../static/images/bjsbs.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
	position: relative;
}
.Cont_onceInfo{
	position: absolute;
  left: 20px;
	top: 30px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
}
.container_centre{
	margin-top: 1%;
}
.BodyMain{
	width: 100%;
	height: 330px;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.BodyMainBg1{
	width: 100%;
	height: 330px;
	background-image: url('../../../static/main/OverviewProductionExecution.png');
	background-size: 100% 330px;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMainBg2{
	width: 100%;
	height: 330px;
	background-image: url('../../../static/main/OverviewProductionExecution.png');
	background-size: 100% 330px;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMainBg3{
	width: 100%;
	height: 330px;
	background-image: url('../../../static/main/OverviewProductionExecution2.png');
	background-size: 100% 330px;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMain2{
	position: relative;
	width: 100%;
	height: 365px;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.BodyMainBg4{
	position: relative;
	width: 100%;
	height: 365px;
	background-image: url('../../../static/main/OverviewProductionExecution3.png');
	background-size: 100% 365px;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMainBg5{
	position: relative;
	width: 100%;
	height: 365px;
	background-image: url('../../../static/main/OverviewProductionExecution4.png');
	background-size: 100% 365px;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMainBg6{
	position: relative;
	width: 100%;
	height: 365px;
	background-image: url('../../../static/main/OverviewProductionExecution4.png');
	background-size: 100% 365px;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.container_bottom{
    margin-top: 1%;
}
.detailed{
	width: 95%;
	margin: 25px auto 0;
	height: auto;
}
.production{
	position: relative;
	width: 100%;
	height: 65px;
	margin-bottom:20px;
	border-radius: 4px;
	white-space: nowrap;
}
.production span:nth-child(1){
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 65px;
	height: 65px;
	border-radius: 4px;
}
.production img{
	display: inline-block;
	width: 100%;
	height: 100%;
}
.production span:nth-child(2){
	display: inline-block;
	position: absolute;
	top: 10px;
	left: 76px;
	font-size: 14px;
	@include font_color("font_color_white");
	
}
.production .span1{
	display: inline-block;
	position: absolute;
	top: 34px;
	left: 76px;
	font-size:24px;
	// @include font_color("font2_color_white");
	color:#00d392;
	// font-weight: 600;
	font-family: 'GemunuLibre-Regular';
}
.production .span2{
	display: inline-block;
	position: absolute;
	top: 34px;
	left: 76px;
	font-size:24px;
	// @include font_color("font2_color_white");
	color:#ff9600;
	// font-weight: 600;
	font-family: 'GemunuLibre-Regular';
}
.production .span3{
	display: inline-block;
	position: absolute;
	top: 34px;
	left: 76px;
	font-size:24px;
	// @include font_color("font2_color_white");
	color:#0cc0ff;
	// font-weight: 600;
	font-family: 'GemunuLibre-Regular';
}
.efficiency{
	width: 95%;
	margin: 10px auto 0;
	height: 250px;
}
.efficiency_l{
	display: inline-block;
	position: relative;
	text-align: center;
	width: 45%;
	height: 100%;
	float: left;
}
.efficiency_l .span1{
	display: inline-block;
	width: 100%;
	font-size: 14px;
	letter-spacing: 2px;
	@include font_color("font_color_white");
	white-space:nowrap;
	position: relative;
	top: -32px;
}
.efficiency_l .span2{
	display: inline-block;
	width: 100%;
	font-size: 14px;
	@include font_color("font_color_white");
	white-space:nowrap;
	position: relative;
	top: -32px;
}
.efficiency_r{
	display: inline-block;
	width: 55%;
	height: 100%;
	float: right;
}
.efficiencyList{
	position: relative;
	width: 95%;
	margin: 20px auto;
	height: 65px;
	border-top-right-radius: 4px;
	border-bottom-right-radius:4px;
	border-left: 5px solid #00d392;
	background-color: rgba(0,211,146, 0.2);
}
.efficiencyList span:nth-child(1){
	position: absolute;
	top: 10px;
	left: 20px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.efficiencyList span:nth-child(2){
	position: absolute;
	top: 32px;
	left: 20px;
	font-size: 24px;
	// font-weight: 600;
	// @include font_color("font2_color_white");
	color: #00d392;
	font-family: 'GemunuLibre-Regular';
}
.efficiencyList span:nth-child(3){
	position: absolute;
	top: 10px;
	right: 20px;
	font-size: 14px;
	@include font_color("font2_color_white");
	font-weight: 600;
}
.efficiencyList .unit{ 
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
	// @include font_color("font5_color_white");
	color: #00d392;
	font-family: "SourceHanSansCN-Regular";
}
.efficiencyList2{
	position: relative;
	width: 95%;
	margin: 20px auto 20px;
	height: 65px;
	border-top-right-radius: 4px;
	border-bottom-right-radius:4px;
	border-left: 5px solid #ff9600;
	background-color: rgba(255,150,0, 0.1);
}
.efficiencyList2 span:nth-child(1){
	position: absolute;
	top: 10px;
	left: 20px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.efficiencyList2 span:nth-child(2){
	position: absolute;
	top: 32px;
	left: 20px;
	font-size: 24px;
	// font-weight: 600;
	// @include font_color("font2_color_white");
	color: #ff9600;
	font-family: 'GemunuLibre-Regular';
}
.efficiencyList2 span:nth-child(3){
	position: absolute;
	top: 10px;
	right: 20px;
	font-size: 14px;
	@include font_color("font2_color_white");
	font-weight: 600;
}
.efficiencyList2 .unit{ 
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
	// @include font_color("font5_color_white");
	color: #ff9600;
	font-family: "SourceHanSansCN-Regular";
}
.efficiencyList3{
	position: relative;
	width: 95%;
	margin: 20px auto 20px;
	height: 65px;
	border-top-right-radius: 4px;
	border-bottom-right-radius:4px;
	border-left: 5px solid #d00032;
	background-color: rgba(208,0,50, 0.1);
}
.efficiencyList3 span:nth-child(1){
	position: absolute;
	top: 10px;
	left: 20px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.efficiencyList3 span:nth-child(2){
	position: absolute;
	top: 32px;
	left: 20px;
	font-size: 24px;
	// font-weight: 600;
	// @include font_color("font2_color_white");
	color: #d00032;
	font-family: 'GemunuLibre-Regular';
}
.efficiencyList3 span:nth-child(3){
	position: absolute;
	top: 10px;
	right: 20px;
	font-size: 14px;
	@include font_color("font2_color_white");
	font-weight: 600;
}
.efficiencyList3 .unit{ 
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	// @include font_color("font5_color_white");
	color: #d00032;
	font-family: "SourceHanSansCN-Regular";
}
.Energy{
	width: 95%;
	margin: 30px auto 0;
	height: auto;
}
.EnergyCont{
	position: relative;
	width: 100%;
	height: 65px;
	margin-bottom:20px;
	@include border_color("border_color_white");
}
.EnergyCont span:nth-child(1){
	display: inline-block;
	position: absolute;
	top: 2px;
	left: 4px;
	width: 47px;
	height: 47px;
	border-radius: 4px;
}
.EnergyCont img{
	display: inline-block;
	width: 100%;
	height: 100%;
}
.EnergyCont span:nth-child(2){
	display: inline-block;
	position: absolute;
	top: 0px;
	left: 66px;
	font-size:24px;
	// @include font_color("font2_color_white");
	color: #00d392;
	// font-weight: 600;
	font-family: 'GemunuLibre-Regular';
}
.EnergyCont span:nth-child(3){
	display: inline-block;
	position: absolute;
	top: 30px;
	left: 66px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.EnergyCont .unit{
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	// @include font_color("font5_color_white");
	color:#00d392;
	font-family: "SourceHanSansCN-Regular";
}
.progress{
	width: 95%;
	margin:20px auto;
}
.progress2{
	width: 95%;
	margin:0px auto;
}
.progress_l{
	display: inline-block;
	width: 50%;
	text-align: left;
	font-size: 14px;
	@include font_color("font_color_white");
}
.progress_r{
	display: inline-block;
	width: 50%;
	text-align: right;
	font-size: 24px;
	// font-weight: 600;
	// @include font_color("font2_color_white");
	color:#00d392;
	font-family: 'GemunuLibre-Regular';
}
.progress2_l{
	display: inline-block;
	width: 50%;
	text-align: left;
	font-size: 14px;
	@include font_color("font_color_white");
}
.progress2_r{
	display: inline-block;
	width: 50%;
	text-align: right;
	font-size: 16px;
	font-weight: 600;
	// @include font_color("font2_color_white");
	color:#00d392;
}
.maintenance{
	width: 95%;
	margin: 20px auto 0;
	height: auto;
}
.maintenanceCont{
	width: 100%;
	height: 60px;
	text-align: center;
}
.maintenanceCont span{
	display: block;
	width: 100%;
}
.maintenanceCont span:nth-child(1){
	font-size: 24px;
	font-family: 'GemunuLibre-Regular';
}
.maintenanceCont span:nth-child(2){
	font-size: 14px;
	@include font_color("font_color_white");
}
.callPolice{
	width: 95%;
	margin: 20px auto 0;
	height: auto;
}
#score {
	width: 100%;
	margin: auto;
	height: 230px;
	margin-top: -10px;
}
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
.container_top{
	width:100%;
	height:12vh;
	border-radius:4px;
}
.Cont_DIv_left{
	height: 12vh;
	background-image: url('../../../static/images/Totalnumber.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.Cont_DIv_right{
	height: 12vh;
	background-image: url('../../../static/images/bjsbs.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
	position: relative;
}
.Cont_onceInfo{
	position: absolute;
  left: 20px;
	top: 3vh;
	display: flex;
	flex-direction: column;
	cursor: pointer;
}
.container_centre{
	margin-top: 1%;
}
.BodyMain{
	width: 100%;
	height: 33vh;
	// @include background_color("background_color_white");
	border-radius: 4px;
}
.BodyMainBg1{
	width: 100%;
	height: 33vh;
	background-image: url('../../../static/main/OverviewProductionExecution.png');
	background-size: 100% 33vh;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMainBg2{
	width: 100%;
	height: 33vh;
	background-image: url('../../../static/main/OverviewProductionExecution.png');
	background-size: 100% 33vh;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMainBg3{
	width: 100%;
	height: 33vh;
	background-image: url('../../../static/main/OverviewProductionExecution2.png');
	background-size: 100% 33vh;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMain2{
	position: relative;
	width: 100%;
	height: 36.5vh;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.BodyMainBg4{
	position: relative;
	width: 100%;
	height: 36.5vh;
	background-image: url('../../../static/main/OverviewProductionExecution3.png');
	background-size: 100% 36.5vh;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMainBg5{
	position: relative;
	width: 100%;
	height: 36.5vh;
	background-image: url('../../../static/main/OverviewProductionExecution4.png');
	background-size: 100% 36.5vh;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.BodyMainBg6{
	position: relative;
	width: 100%;
	height: 36.5vh;
	background-image: url('../../../static/main/OverviewProductionExecution5.png');
	background-size: 100% 36.5vh;
	background-repeat: no-repeat;
	background-position:center;
	border-radius: 4px;
}
.container_bottom{
    margin-top: 1%;
}
.detailed{
	width: 95%;
	margin: 25px auto 0;
	height: auto;
}
.production{
	position: relative;
	width: 100%;
	height: 6.5vh;
	margin-bottom:2vh;
	border-radius: 4px;
	white-space: nowrap;
}
.production span:nth-child(1){
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 6.5vh;
	height: 6.5vh;
	border-radius: 4px;
}
.production img{
	display: inline-block;
	width: 100%;
	height: 100%;
}
.production span:nth-child(2){
	display: inline-block;
	position: absolute;
	top: 1vh;
	left: 76px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.production .span1{
	display: inline-block;
	position: absolute;
	top: 3.4vh;
	left: 76px;
	font-size:24px;
	// @include font_color("font2_color_white");
	color:#00d392;
	// font-weight: 600;
	font-family: 'GemunuLibre-Regular';
}
.production .span2{
	display: inline-block;
	position: absolute;
	top: 3.4vh;
	left: 76px;
	font-size:24px;
	// @include font_color("font2_color_white");
	color:#ff9600;
	// font-weight: 600;
	font-family: 'GemunuLibre-Regular';
}
.production .span3{
	display: inline-block;
	position: absolute;
	top: 3.4vh;
	left: 76px;
	font-size:24px;
	// @include font_color("font2_color_white");
	color:#0cc0ff;
	// font-weight: 600;
	font-family: 'GemunuLibre-Regular';
}
.efficiency{
	width: 95%;
	margin: 1vh auto 0;
	height: 25vh;
}
.efficiency_l{
	display: inline-block;
	position: relative;
	text-align: center;
	width: 45%;
	height: 100%;
	float: left;
}
.efficiency_l .span1{
	display: inline-block;
	width: 100%;
	font-size: 14px;
	letter-spacing: 2px;
	@include font_color("font_color_white");
	white-space:nowrap;
	position: relative;
	top: -3.2vh;
}
.efficiency_l .span2{
	display: inline-block;
	width: 100%;
	font-size: 14px;
	@include font_color("font_color_white");
	white-space:nowrap;
	position: relative;
	top: -3.2vh;
}
.efficiency_r{
	display: inline-block;
	width: 55%;
	height: 100%;
	float: right;
}
.efficiencyList{
	position: relative;
	width: 95%;
	margin:20px auto;
	height: 6.5vh;
	border-top-right-radius: 4px;
	border-bottom-right-radius:4px;
	border-left: 5px solid #00d392;
	background-color: rgba(0,211,146, 0.2);
}
.efficiencyList span:nth-child(1){
	position: absolute;
	top: 1vh;
	left: 20px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.efficiencyList span:nth-child(2){
	position: absolute;
	top: 3.2vh;
	left: 20px;
	font-size: 24px;
	// font-weight: 600;
	// @include font_color("font2_color_white");
	color: #00d392;
	font-family: 'GemunuLibre-Regular';
}
.efficiencyList span:nth-child(3){
	position: absolute;
	top: 1vh;
	right: 20px;
	font-size: 14px;
	@include font_color("font2_color_white");
}
.efficiencyList .unit{ 
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	// @include font_color("font5_color_white");
	color: #00d392;
	font-family: "SourceHanSansCN-Regular";
}
.efficiencyList2{
	position: relative;
	width: 95%;
	margin: 2vh auto 2vh;
	height: 6.5vh;
	border-top-right-radius: 4px;
	border-bottom-right-radius:4px;
	border-left: 5px solid #ff9600;
	background-color: rgba(255,150,0, 0.1);
}
.efficiencyList2 span:nth-child(1){
	position: absolute;
	top: 1vh;
	left: 20px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.efficiencyList2 span:nth-child(2){
	position: absolute;
	top: 3.2vh;
	left: 20px;
	font-size: 24px;
	// font-weight: 600;
	// @include font_color("font2_color_white");
	color: #ff9600;
	font-family: 'GemunuLibre-Regular';
}
.efficiencyList2 span:nth-child(3){
	position: absolute;
	top: 1vh;
	right: 20px;
	font-size: 14px;
	@include font_color("font2_color_white");
}
.efficiencyList2 .unit{ 
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	// @include font_color("font5_color_white");
	color: #ff9600;
	font-family: "SourceHanSansCN-Regular";
}
.efficiencyList3{
	position: relative;
	width: 95%;
	margin: 2vh auto 2vh;
	height: 6.5vh;
	border-top-right-radius: 4px;
	border-bottom-right-radius:4px;
	border-left: 5px solid #d00032;
	background-color: rgba(208,0,50, 0.1);
}
.efficiencyList3 span:nth-child(1){
	position: absolute;
	top: 1vh;
	left: 20px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.efficiencyList3 span:nth-child(2){
	position: absolute;
	top: 3.2vh;
	left: 20px;
	font-size: 24px;
	// font-weight: 600;
	// @include font_color("font2_color_white");
	color: #d00032;
	font-family: 'GemunuLibre-Regular';
}
.efficiencyList3 span:nth-child(3){
	position: absolute;
	top: 1vh;
	right: 20px;
	font-size: 14px;
	@include font_color("font2_color_white");
}
.efficiencyList3 .unit{ 
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	// @include font_color("font5_color_white");
	color: #d00032;
	font-family: "SourceHanSansCN-Regular";
}
.Energy{
	width: 95%;
	margin: 3vh auto 0;
	height: auto;
}
.EnergyCont{
	position: relative;
	width: 100%;
	height: 6.5vh;
	margin-bottom:2vh;
	@include border_color("border_color_white");
}
.EnergyCont span:nth-child(1){
	display: inline-block;
	position: absolute;
	top: 0.2vh;
	left: 4px;
	width: 47px;
	height: 47px;
	border-radius: 4px;
}
.EnergyCont img{
	display: inline-block;
	width: 100%;
	height: 100%;
}
.EnergyCont span:nth-child(2){
	display: inline-block;
	position: absolute;
	top: 0vh;
	left: 66px;
	font-size:24px;
	// @include font_color("font2_color_white");
	color:#00d392;
	// font-weight: 600;
	font-family: 'GemunuLibre-Regular';
}
.EnergyCont span:nth-child(3){
	display: inline-block;
	position: absolute;
	top: 3vh;
	left: 66px;
	font-size: 14px;
	@include font_color("font_color_white");
}
.EnergyCont .unit{
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	// @include font_color("font5_color_white");
	color:#00d392;
	font-family: "SourceHanSansCN-Regular";
}
.progress{
	width: 95%;
	margin: 2vh auto;
}
.progress2{
	width: 95%;
	margin: 0vh auto;
}
.progress_l{
	display: inline-block;
	width: 50%;
	text-align: left;
	font-size: 14px;
	@include font_color("font_color_white");
}
.progress_r{
	display: inline-block;
	width: 50%;
	text-align: right;
	font-size: 24px;
	// font-weight: 600;
	// @include font_color("font2_color_white");
	color:#00d392;
	font-family: 'GemunuLibre-Regular';
}
.progress2_l{
	display: inline-block;
	width: 50%;
	text-align: left;
	font-size: 14px;
	@include font_color("font_color_white");
}
.progress2_r{
	display: inline-block;
	width: 50%;
	text-align: right;
	font-size: 16px;
	font-weight: 600;
	// @include font_color("font2_color_white");
	color:#00d392;
}
.maintenance{
	width: 95%;
	margin: 2vh auto 0;
	height: auto;
}
.maintenanceCont{
	width: 100%;
	height: 6vh;
	text-align: center;
}
.maintenanceCont span{
	display: block;
	width: 100%;
}
.maintenanceCont span:nth-child(1){
	font-size: 24px;
	font-family: 'GemunuLibre-Regular';
}
.maintenanceCont span:nth-child(2){
	font-size: 14px;
	@include font_color("font_color_white");
}
.callPolice{
	width: 95%;
	margin: 2vh auto 0;
	height: auto;
}
#score {
	width: 100%;
	margin: auto;
	height: 23vh;
	margin-top: -1vh;
}
}
</style>
