/*
* 版权声明: 北京能创科技有限公司
* 创建者 : 崔君
* 创建日期: 2018/10/18
* 文件描述:
*/

//字符串长度
String.prototype.stringLength = function() {
  var len = 0;
  for (var i=0; i<this.length; i++) {
    if (this.charCodeAt(i)>127 || this.charCodeAt(i)==94) {
       len += 2;
     } else {
       len ++;
     }
   }
  return len;
}
Array.prototype.indexOf = function(val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) return i;
  }
  return -1;
};
Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};
//深继承
export function extend(des, src, override){
   if(src instanceof Array){
       for(var i = 0, len = src.length; i < len; i++)
            extend(des, src[i], override);
   }
   for( var i in src){
       if(override || !(i in des)){
           des[i] = src[i];
       }
   }
   return des;
}
//判断是否为空
export function isEmpty(data) {
	if (typeof(data) == "string"){
		return isEmptyString(data);
	}else if(typeof(data) == "object" ){
		return isEmptyObject(data);
	}else if(typeof(data) == "undefined" ){
		return true;
	}
}

function isEmptyString(dataString) {
	if (dataString === "null" || dataString === "NULL" || dataString === "[]" || dataString === "" || dataString === "undefined" || dataString === "{}"){
		return true;
	}else{
		return false;
	}
}
function isEmptyObject(obj) {
	for ( var name in obj ) {
		return false;
	}
	return true;
}
//替换json字段名
export function replaceKeyName(arr){
	var a = JSON.stringify(arr).replace(/AgencyId/g, "id").replace(/AgencyName/g, "text").replace(/NodeList/g, "children").replace(/DeviceId/g, "id").replace(/name/g, "label");
	var b = JSON.parse(a);
	return b;
}
//组织机构管理
export function replaceKeyName2(arr){
	var a = JSON.stringify(arr).replace(/AgencyId/g, "id").replace(/AgencyName/g, "label").replace(/NodeList/g, "children");
	var b = JSON.parse(a);
	return b;
}
//位置管理
export function replaceKeyName3(arr){
	var a = JSON.stringify(arr).replace(/NodeList/g, "children");
	var b = JSON.parse(a);
	return b;
}
//替换json字段名 设备管理 excel upload
export function replaceKeyName_nodeDataArray(arr){
	var a = JSON.stringify(arr).replace(/MeterID/g, "key").replace(/ParentID/g, "parent").replace(/车间10KV变电所/g, "name");
	var b = JSON.parse(a);
	return b;
}
export function replaceKeyName_linkDataArray(arr){
	var a = JSON.stringify(arr).replace(/MeterID/g, "to").replace(/ParentID/g, "from");
	var b = JSON.parse(a);
	return b;
}
//数组去重
export function uniq(array){
    var temp = {}, r = [], len = array.length, val, type;
    for (var i = 0; i < len; i++) {
        val = array[i];
        type = typeof val;
        if (!temp[val]) {
            temp[val] = [type];
            r.push(val);
        } else if (temp[val].indexOf(type) < 0) {
            temp[val].push(type);
            r.push(val);
        }
    }
    return r;
}
//最小值
Array.prototype.min = function() {
	let len = this.length;
  let list = [];
  for(let k=0; k < len; k++){
    if (!isNaN(this[k]) && this[k] !== ""){
      list.push(this[k]);
  	}
  }
  var min = list[0];
  let length = list.length;
	for (let i = 1; i < length; i++) {
		if (list[i] < min) {
			min = list[i];
		}
	}
  if(length===0) min = 0;
	return min;
}
//最大值
Array.prototype.max = function() {
	let len = this.length;
  let list = [];
  for(let k=0; k < len; k++){
    if (!isNaN(this[k]) && this[k] !== ""){
      list.push(this[k]);
    }
  }
  var max = list[0];
  let length = list.length;
	for (let i = 1; i < length; i++) {
		if (list[i] > max) {
			max = list[i];
		}
	}
  if(length===0) max = 0;
	return max;
}
//获取指定字符前面的所有内容
export function getCaption(obj,s){
    var index=obj.lastIndexOf(s);
    obj=obj.substring(0,index+1);
    return obj;
}
//将数字转换成金额显示
export function toMoney(num){
    if(isEmpty(num)) return "";
    num = num.toFixed(2);
    num = parseFloat(num)
    num = num.toLocaleString();
    return num;//返回的是字符串23,245.12保留2位小数
}
//将金额转换成数字显示
export function moneyToNumValue(val) {
  if(isEmpty(val)) return "";
	var num = val.trim();
	var ss = num.toString();
	if (ss.length == 0) {
		return "0";
	}
	return ss.replace(/,/g, "");
}

//tools
export const forEach = (arr, fn) => {
  if (!arr.length || !fn) return
  let i = -1
  let len = arr.length
  while (++i < len) {
    let item = arr[i]
    fn(item, i, arr)
  }
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的交集, 两个数组的元素为数值或字符串
 */
export const getIntersection = (arr1, arr2) => {
  let len = Math.min(arr1.length, arr2.length)
  let i = -1
  let res = []
  while (++i < len) {
    const item = arr2[i]
    if (arr1.indexOf(item) > -1) res.push(item)
  }
  return res
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
  return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr, arr) => {
  return targetarr.some(_ => arr.indexOf(_) > -1)
}

/**
 * @param {String|Number} value 要验证的字符串或数值
 * @param {*} validList 用来验证的列表
 */
export function oneOf (value, validList) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true
    }
  }
  return false
}

/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */
const isMillisecond = timeStamp => {
  const timeStr = String(timeStamp)
  return timeStr.length > 10
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} currentTime 当前时间时间戳
 * @returns {Boolean} 传入的时间戳是否早于当前时间戳
 */
const isEarly = (timeStamp, currentTime) => {
  return timeStamp < currentTime
}

/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */
const getHandledValue = num => {
  return num < 10 ? "0" + num : num
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
 */
const getDate = (timeStamp, startType) => {
  const d = new Date(timeStamp * 1000)
  const year = d.getFullYear()
  const month = getHandledValue(d.getMonth() + 1)
  const date = getHandledValue(d.getDate())
  const hours = getHandledValue(d.getHours())
  const minutes = getHandledValue(d.getMinutes())
  const second = getHandledValue(d.getSeconds())
  let resStr = ""
  if (startType === "year") resStr = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + second
  else resStr = month + "-" + date + " " + hours + ":" + minutes
  return resStr
}


/**
 * @returns {String} 当前浏览器名称
 */
export const getExplorer = () => {
  const ua = window.navigator.userAgent
  const isExplorer = (exp) => {
    return ua.indexOf(exp) > -1
  }
  if (isExplorer("MSIE")) return "IE"
  else if (isExplorer("Firefox")) return "Firefox"
  else if (isExplorer("Chrome")) return "Chrome"
  else if (isExplorer("Opera")) return "Opera"
  else if (isExplorer("Safari")) return "Safari"
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent("on" + event, handler)
      }
    }
  }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent("on" + event, handler)
      }
    }
  }
})()

/**
 * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * 如果没有传入key这个参数，则判断obj对象是否有键值对
 */
export const hasKey = (obj, key) => {
  if (key) return key in obj
  else {
    let keysArr = Object.keys(obj)
    return keysArr.length
  }
}

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) return false
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true
  /* eslint-disable-next-line */
  else return !keysArr1.some(key => obj1[key] != obj2[key])
}

export function convert(val){
  let percent = document.body.clientWidth/1536;
  return Number((val*percent).toFixed(3));
}
export function convertImg(val){
  if(document.body.clientWidth<=1024){
    var percent = document.body.clientWidth/1570;
  }else if(document.body.clientWidth>=3072){
    var percent = document.body.clientWidth/1600;
  }else{
    var percent = document.body.clientWidth/1603;
  }
  return Number((val*percent).toFixed(3));
}
export function convertImg2(val){
  if(document.body.clientWidth<=1024){
    var percent = document.body.clientWidth/1570;
  }else if(document.body.clientWidth>=3072){
    var percent = document.body.clientWidth/1600;
  }else{
    var percent = document.body.clientWidth/1920;
  }
  return Number((val*percent).toFixed(3));
}

export function random() { // 生成10-12位不等的字符串
    return Math.random().toString(36).slice(2); // 截取小数点后的字符串
}
export function milliFormat(num){//添加千位符
  var res=num.toString().replace(/\d+/, function(n){ // 先提取整数部分
       return n.replace(/(\d)(?=(\d{3})+$)/g,function($1){
          return $1+",";
        });
  })
  return res;
}
export function milliFormat2(s){//保留两位，添加千位符
	return s.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");//使用正则替换，每隔三个数加一个','
}
export function milliFormat0(s){//保留0位，添加千位符
	return s.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").split(".")[0];//使用正则替换，每隔三个数加一个','
}
var submited = false;
export function checkSubmit() {
    if (!submited){
      submited = true;
      return true;
    }else {
      setTimeout(()=>{
        submited = false;
      },1200)
      // alert("请不要重复提交！");
      return false;
    }
}
//千位分隔符
export function numFormat(num){
  var res=num.toString().replace(/\d+/, function(n){ // 先提取整数部分
       return n.replace(/(\d)(?=(\d{3})+$)/g,function($1){
          return $1+",";
        });
  })
  return res;
}
// 获取浏览器默认语言
export const getBrowserLang = function() {
  let browserLang = navigator.language ? navigator.language: navigator.browserLanguage;
  let defaultBrowserLang = '';
    if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
        defaultBrowserLang = 'zh'
    } else {
        defaultBrowserLang = 'en'
    }
    return defaultBrowserLang
};
