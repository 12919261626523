
/* 日期格式化
 *
 * @date yyyy-mm-dd
 */
export function formatDate (date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}
// 短日期，形如 (2008-07-22)
export function strDateTime(str)
{
var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
// console.log(r);
if(r==null)return false;
var d= new Date(r[1], r[3]-1, r[4]);
// console.log((d.getFullYear()==r[1]&&(d.getMonth()+1)==r[3]&&d.getDate()==r[4]));
return (d.getFullYear()==r[1]&&(d.getMonth()+1)==r[3]&&d.getDate()==r[4]);
}

function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}
/**
 * 计算两个日期之间的天数
 * @param dateString1  开始日期 yyyy-MM-dd
 * @param dateString2  结束日期 yyyy-MM-dd
 * @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
 */
export function  getDaysBetween(dateString1,dateString2){
    var  startDate = Date.parse(dateString1);
    var  endDate = Date.parse(dateString2);
    if (startDate>endDate){
        return 0;
    }
    if (startDate==endDate){
        return 1;
    }
    var days=(endDate - startDate)/(1*24*60*60*1000);
    return  days;
}
/* 获取上一个月
 *
 * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
 */
export function getPreMonth(date) {
    var arr = date.split('-');
    var year = arr[0]; //获取当前日期的年份
    var month = arr[1]; //获取当前日期的月份
    var day = arr[2]; //获取当前日期的日
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    var year2 = year;
    var month2 = parseInt(month) - 1;
    if (month2 == 0) {
        year2 = parseInt(year2) - 1;
        month2 = 12;
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
}

/**
 * 获取下一个月
 *
 * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
 */
export function getNextMonth(date) {
    var arr = date.split('-');
    var year = arr[0]; //获取当前日期的年份
    var month = arr[1]; //获取当前日期的月份
    var day = arr[2]; //获取当前日期的日
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中的月的天数
    var year2 = year;
    var month2 = parseInt(month) + 1;
    if (month2 == 13) {
        year2 = parseInt(year2) + 1;
        month2 = 1;
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }

    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
}
//获取当前时间
export function getNowFormatDate() {
    var date = new Date();
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var Setseconds = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
	if (hours >= 0 && hours <= 9) {
	    hours = "0" + hours;
	}
	if (minutes >= 0 && minutes <= 9) {
	    minutes = "0" + minutes;
	}
	if (Setseconds >= 0 && Setseconds <= 9) {
	    Setseconds = "0" + Setseconds;
	}
    // var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
    //         + " " + hours + seperator2 + minutes
    //         + seperator2 + Setseconds;
	var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
	+ " " + hours + seperator2 + minutes;
    return currentdate;
}
// 明年的今天
export function getDayOfNextYear(date) {
  var pre = new Date(date);
  pre.setFullYear(pre.getFullYear()+1);
  return formatDate (pre, "yyyy-MM-dd hh:mm:ss");
}
// 去年的今天
export function getDayOfPreYear() {
  var pre = new Date();
  pre.setFullYear(pre.getFullYear()-1);
  return formatDate (pre, "yyyy-MM-dd");
}

// 上月的今天
export function getDayOfPreMonth() {
  var now=new Date();
  var year = now.getFullYear();//getYear()+1900=getFullYear()
  var month = now.getMonth() +1;//0-11表示1-12月
  var day = now.getDate();
  if(parseInt(month)<10){
      month="0"+month;
  }
  if(parseInt(day)<10){
      day="0"+day;
  }

  now =year + '-'+ month + '-' + day;

  if (parseInt(month) ==1) {//如果是1月份，则取上一年的12月份
      return (parseInt(year) - 1) + '-12-' + day;
  }

  var  preSize= new Date(year, parseInt(month)-1, 0).getDate();//上月总天数
  if (preSize < parseInt(day)) {//上月总天数<本月日期，比如3月的30日，在2月中没有30
      return year + '-' + month + '-01';
  }

  if(parseInt(month) <=10){
      return year + '-0' + (parseInt(month)-1) + '-' + day;
  }else{
      return year + '-' + (parseInt(month)-1) + '-' + day;
  }
}
// 上周的今天
export function getDayOfPreWeek() {
  var date1 = new Date(),
  time1=date1.getFullYear()+"-"+(date1.getMonth()+1)+"-"+date1.getDate();//time1表示当前时间
  var date2 = new Date(date1);
  date2.setDate(date1.getDate()-6);
  var time2 = date2.getFullYear()+"-"+(date2.getMonth()+1)+"-"+date2.getDate();
  return time2;
}
// 去年的今月
export function getMonthOfPreYear() {
  var pre = new Date();
  pre.setFullYear(pre.getFullYear()-1);
  return formatDate (pre, "yyyy-MM");
}

//YYYY-MM-DD +1.-1
export function addDate(date,days){
  var a = new Date(date)
  a = a.valueOf()
  a = a + days * 24 * 60 * 60 * 1000
  a = new Date(a)
  return formatDate (a, "yyyy-MM-dd");
}
//YYYY-MM-DD +1.-1
export function addMMDate(date,days){
  var a = new Date(date)
  a = a.valueOf()
  a = a + days * 24 * 60 * 60 * 1000
  a = new Date(a)
  return formatDate (a, "yyyy-MM");
}
 /**
 * 两个时间相减
 * @param starttime
 * @param endtime
 * @returns
 */
export function GetDateDiff(starttime,endtime,type)
{
   if( starttime == null || endtime == null){
    return "";
   }
   var ed = endtime;
   var sd = starttime ;

   // if(type==1){
   //    ed = ed+":00";
   // }else if(type ==2){
   //  sd = sd +":00";
   // }
   //此处是解决浏览器兼容性问题，Firefox只能认识2015/06/05格式
      var sd2 = sd.replace(/-/g,"/");
      var ed2 = ed.replace(/-/g,"/");

   var startTime = new Date(sd2);
   var endTime = new Date(ed2);
   var result = "";

   var date3=endTime.getTime()-startTime.getTime(); //时间差的毫秒数

   //计算出相差天数
   var days=Math.floor(date3/(24*3600*1000));

   result += days > 0 ? days + i18n.t('common.天') : "0"+i18n.t('common.天');
   //计算出小时数
   var leave1=date3%(24*3600*1000);     //计算天数后剩余的毫秒数
   var hours=Math.floor(leave1/(3600*1000));

   result += hours > 0 ? hours + i18n.t('common.小时') : "0"+i18n.t('common.小时');

   //计算相差分钟数
   var leave2=leave1%(3600*1000);        //计算小时数后剩余的毫秒数
   var minutes=Math.floor(leave2/(60*1000));

   result += minutes > 0 ? minutes + i18n.t('common.分钟') : "0"+i18n.t('common.分钟');
   //计算相差秒数
  // var leave3=leave2%(60*1000);          //计算分钟数后剩余的毫秒数
  // var seconds=Math.round(leave3/1000);
  //
  // result += seconds > 0 ? seconds + "秒" : "";

   return result == "" ? "" : result;
}

//1.显示日期代码：

/*获取当前日期*/
export function getCurrentDateTime() {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var date = d.getDate();
    var week = d.getDay();
    /*时分秒*/
    /*var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();
    var ms = d.getMilliseconds();*/
    var curDateTime = year;
    if (month > 9)
        curDateTime = curDateTime + i18n.t('common.年') + month;
    else
        curDateTime = curDateTime + i18n.t('common.年')+"0" + month;
    if (date > 9)
        curDateTime = curDateTime + i18n.t('common.月') + date + i18n.t('common.日');
    else
        curDateTime = curDateTime + i18n.t('common.月')+"0" + date +i18n.t('common.日');
    /*if (hours > 9)
        curDateTime = curDateTime + " " + hours;
    else
        curDateTime = curDateTime + " 0" + hours;
    if (minutes > 9)
        curDateTime = curDateTime + ":" + minutes;
    else
        curDateTime = curDateTime + ":0" + minutes;
    if (seconds > 9)
        curDateTime = curDateTime + ":" + seconds;
    else
        curDateTime = curDateTime + ":0" + seconds;*/
    var weekday = "";
    if (week == 0)
        weekday = i18n.t('common.星期日');
    else if (week == 1)
        weekday = i18n.t('common.星期一');
    else if (week == 2)
        weekday = i18n.t('common.星期二');
    else if (week == 3)
        weekday = i18n.t('common.星期三');
    else if (week == 4)
        weekday = i18n.t('common.星期四');
    else if (week == 5)
        weekday = i18n.t('common.星期五');
    else if (week == 6)
        weekday = i18n.t('common.星期六');
    curDateTime = curDateTime + " " + weekday;
    return curDateTime;
}
export function getCurrentDateTime_en() {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var date = d.getDate();
    var week = d.getDay();
    /*时分秒*/
    var curDateTime = year;
    if (month > 9)
        curDateTime = curDateTime + "-" + month;
    else
        curDateTime = curDateTime + "-" +"0" + month;
    if (date > 9)
        curDateTime = curDateTime + "-" + date;
    else
        curDateTime = curDateTime + "-" +"0" + date +i18n.t('common.日');

    var weekday = "";
    if (week == 0)
        weekday = i18n.t('common.星期日');
    else if (week == 1)
        weekday = i18n.t('common.星期一');
    else if (week == 2)
        weekday = i18n.t('common.星期二');
    else if (week == 3)
        weekday = i18n.t('common.星期三');
    else if (week == 4)
        weekday = i18n.t('common.星期四');
    else if (week == 5)
        weekday = i18n.t('common.星期五');
    else if (week == 6)
        weekday = i18n.t('common.星期六');
    curDateTime = curDateTime + " " + weekday;

    return curDateTime;
}

//2.显示农历代码：

/*获取当前农历*/
export function showCal(){
	var D=new Date();
	var yy=D.getFullYear();
	var mm=D.getMonth()+1;
	var dd=D.getDate();
	var ww=D.getDay();
	var ss=parseInt(D.getTime() / 1000);
	if (yy<100) yy="19"+yy;
	return GetLunarDay(yy,mm,dd);
}

function GetBit(m,n){
	return (m>>n)&1;
}
//农历转换
function e2c(){
	TheDate= (arguments.length!=3) ? new Date() : new Date(arguments[0],arguments[1],arguments[2]);
	var total,m,n,k;
	var isEnd=false;
	var tmp=TheDate.getYear();
	if(tmp<1900){
 		tmp+=1900;
	}
	total=(tmp-1921)*365+Math.floor((tmp-1921)/4)+madd[TheDate.getMonth()]+TheDate.getDate()-38;

	if(TheDate.getYear()%4==0&&TheDate.getMonth()>1) {
	 	total++;
	}
	for(m=0;;m++){
	 	k=(CalendarData[m]<0xfff)?11:12;
	 	for(n=k;n>=0;n--){
			if(total<=29+GetBit(CalendarData[m],n)){
	 			isEnd=true; break;
	 		}
			 total=total-29-GetBit(CalendarData[m],n);
	 	}
	 	if(isEnd) break;
	}
	cYear=1921 + m;
	cMonth=k-n+1;
	cDay=total;
	if(k==12){
		 if(cMonth==Math.floor(CalendarData[m]/0x10000)+1){
		 	cMonth=1-cMonth;
		 }
		if(cMonth>Math.floor(CalendarData[m]/0x10000)+1){
		 	cMonth--;
		 }
	}
}

function GetLunarDay(solarYear,solarMonth,solarDay){
	//solarYear = solarYear<1900?(1900+solarYear):solarYear;
	if(solarYear<1921 || solarYear>2020){
		return "";
	}else{
	 	solarMonth = (parseInt(solarMonth)>0) ? (solarMonth-1) : 11;
	 	e2c(solarYear,solarMonth,solarDay);
	 	return GetcDateString();
	}
}
